import { marketoService } from "./marketo-service";
import { User } from "./models/user";
import { storageService } from "./storage-service";
import { getUserInfo } from "./user-service";


class EmailCaptureService {
    emailCaptureForm = '1139';
    constructor() { }
    setupForms() {
        let mtkoForm$ = marketoService.loadForm(this.emailCaptureForm);
        document.querySelectorAll<HTMLFormElement>('form[data-email-capture-form]').forEach(async form => {
            const storedUserData = storageService.getUserData();
            form.onsubmit = async (ev) => {
                ev.preventDefault();
                ev.stopPropagation();

                const email = new FormData(form).get("email");

                if (email && email !== storedUserData?.email) {
                    let user:Partial<User> = { email: email.toString() };
                    try {
                        const marketo$ = mtkoForm$.then(mkto => mkto.submit(form));
                        const userInfo$ = getUserInfo(user.email!);
                        const [userInfo, _] = await Promise.allSettled([userInfo$, marketo$]);

                        if (userInfo.status === "rejected") {
                            console.error('User info call failed', userInfo.reason);
                        } else if (userInfo.value.error) {
                            console.error(userInfo.value.error);
                        } else {                            
                            const userName = userInfo.value.name;
                            if (userName) {
                                user.firstName = userName.givenName;
                                user.lastName = userName.familyName;
                            };
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    storageService.setUserData(user);
                }
                window.location.assign(form.dataset.redirect || '/signup');
            }
        });
    }
}

export const emailCaptureService = new EmailCaptureService();